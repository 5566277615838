<template>
    <div class="pageContol listWrap templateList promoteBox">
        <div class="framePage">
            <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">运营工具</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">广告内容配置</a>
        </span>
            </div>
            <div class="framePage-body">
                <div class="operationControl flexdc" style="align-items:flex-start">
                    <div class="searchbox">
                        <div title="主题名称" class="searchboxItem ci-full themeName">
                            <span class="itemLabel">主题名称:</span>
                            <el-input
                                    v-model="themeName"
                                    type="text"
                                    size="small"
                                    placeholder="请输入轮播图名称"
                                    clearable
                            />
                        </div>
                        <div title="上架状态" class="searchboxItem ci-full themeName">
                            <span class="itemLabel">上架状态:</span>
                            <el-select
                                clearable
                                v-model="advertsStatus"
                                placeholder="请选择上架状态"
                                size="small"
                            >
                                <el-option key="10" label="停用" value="10"></el-option>
                                <el-option key="20" label="启用" value="20"></el-option>
                            </el-select>
                        </div>

                        <div class="df">
                            <el-button type="primary" class="bgc-bv" round @click="getData()">查询</el-button>
                            <el-button type="primary" class="bgc-bv" round @click="add('add')">新增</el-button>
                        </div>
                    </div>
                </div>
                <div class="framePage-scroll">
                    <div class="ovy-a">
                        <el-table
                                ref="multipleTable"
                                :data="tableData"
                                :height="tableHeight"
                                size="small"
                                tooltip-effect="dark"
                                style="width: 100%"
                                :header-cell-style="tableHeader"
                        >
                            <el-table-column label="序号" align="center" type="index" :index="indexMethod" width="100"/>
                            <el-table-column
                                    label="主题名称"
                                    align="left"
                                    prop="themeName"
                                    show-overflow-tooltip
                            />
                            <el-table-column label="终端类型" align="left" prop="terminalType" show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <span>{{terminalTypeReturn(scope.row.terminalType) }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="是否指定班级" align="center" show-overflow-tooltip>
                                <template slot-scope="scope">{{scope.row.appointProjectStatus?"是":"否"}}</template>
                            </el-table-column>
                            <el-table-column
                                label="显示位置"
                                align="left"
                                prop="positionType"
                                show-overflow-tooltip
                            >
                                <template slot-scope="scope">
                                    <span>{{positionTypeReturn(scope.row.positionType) }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                label="状态"
                                align="center"
                                prop="advertsStatus"
                                show-overflow-tooltip
                            >
                                <template slot-scope="scope">
                                    <span v-if="scope.row.advertsStatus == '10'" style="color: #F56C6C">停用</span>
                                    <span v-if="scope.row.advertsStatus == '20'" style="color: #67C23A">启用</span>
                                </template>
                            </el-table-column>

                            <el-table-column label="创建日期" align="left" min-width="120">
                                <template slot-scope="scope">
                                    <span>{{ scope.row.createTime | moment }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="操作" align="center">
                                <div slot-scope="scope" class="flexcc">
                                    <el-button
                                        type="text"
                                        style="padding:0px 15px"
                                        size="mini"
                                        @click="handleEdit('edit', scope.row.advertsId)">
                                        编辑
                                    </el-button>
                                    <el-button
                                        type="text"
                                        size="mini"
                                        style="padding: 0 5px;"
                                        @click="setEnable(scope.row)">
                                        {{scope.row.advertsStatus=='10'?"启用":"停用"}}
                                    </el-button>

                                </div>
                            </el-table-column>
                            <Empty slot="empty" />
                        </el-table>
                    </div>
                </div>
                <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
            </div>
        </div>
    </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
export default {
    name: "adInfoConfig",
    components: {
        Empty,
        PageNum
    },
    mixins: [List],
    data() {
        return {
            themeName: "", //轮播图名称
            advertsStatus: "",
        };
    },
    computed: {},
    created() {
        this.getTableHeight();
    },
    methods: {
        // 终端类型
        terminalTypeReturn(e){
            if(e == '10'){
                return '安知APP'
            }else if(e == '20'){
                return 'PC学习端'
            }else if(e == '30'){
                return '安知乐业小程序'
            }
        },
        // 显示位置
        positionTypeReturn(e){
            if(e == '10'){
                return '安知培训播放页面'
            }else if(e == '20'){
                return '精品课详情页面'
            }else if(e == '30'){
                return '首页'
            }
        },
        getData(pageNum = 1) {
            // console.log(this.state);
            const params = {
                pageNum: pageNum,
                pageSize: this.pageSize
            };
            if (this.themeName) {
                params.themeName = this.themeName;
            }
            if (this.advertsStatus) {
                params.advertsStatus = this.advertsStatus;
            }

            this.doFetch({
                url: "/biz/terminalAdverts/pageList",
                params,
                pageNum
            });
        },

        // 启用/停用
        setEnable(row){
            const {advertsId,advertsStatus}=row;
            const title = advertsStatus == '10'?"启用":"停用";
            this.$confirm(`确认${title}该广告?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                confirmButtonClass:"bgc-bv",
            }).then(() => {
                this.$post("/biz/terminalAdverts/statusUpdate",{
                    advertsId : advertsId,
                }).then(()=>{
                    this.getData(-1);
                    this.$message.success("操作成功");
                });
            });
        },

        // 新增
        add(stu) {
            this.$router.push({
                path: "/web/workOrder/adInfoUpdate",
                query: { stu, id: null }
            });
        },
        handleEdit(stu, id) {
            this.$router.push({
                path: "/web/workOrder/adInfoUpdate",
                query: { stu, id }
            });
        },
        getTableHeight(opDom = true, page = true) {
            let tHeight =
                window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
            if (opDom) {
                tHeight -= 40 + 0.675 * 16 + 1 + 40;
            }
            if (page) {
                tHeight -= 25;
            }
            this.tableHeight = tHeight;
        }
    },
    beforeRouteLeave: resetKeepAlive,
    watch: {
        $route: {
            handler: function(val, oldVal) {
                if (val.query.refresh == 'true' || val.query.refresh) {
                    this.getData(-1);
                }
            },

            // 深度观察监听
            deep: true
        }
    }
};
</script>
<style lang="less" scoped>
.addlist {
  .el-icon-plus:before {
    content: "\e6d9";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.promoteBox {
  .themeName {
    .itemLabel {
      min-width: 6rem;
    }
  }
  .state {
    .itemLabel {
      min-width: 6rem;
      // padding-right: 50px;
    }
  }
}
</style>
